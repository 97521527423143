import { OrderDetails, OrderProductCustomerDetails, ProductToAddRequestCustomerItem, Slot } from '@/models/Order';
import swal from 'sweetalert2';
import moment from 'moment';
import { computed, Ref, ref } from 'vue';
import { api } from '@/services/Api';
import Q from 'q';
import { CalculateProductCustomersRequest } from '@/models/Interfaces';
import { CustomerInfo } from '@/models/Order';
import useOrder from './useOrder';
import { useI18n } from 'vue-i18n';
import { CalculateCustomersResponse } from '@/models/Interfaces';
import { CouponLimitationType } from '@/models/Enums';

export default function(order: Ref<OrderDetails>) {
    const { t } = useI18n();
    const { removemillis, getEndTime } = useOrder();
    const calculated = ref(false);
    const qtyNotOversized = ref(false);
    const customerTypes = ref<CustomerInfo[]>([]);

    const valid = computed(() => {
        if (!calculated.value || !order.value.newproduct.customers || order.value.newproduct.customers.length === 0) {
            return false;
        }
        const exist = order.value.newproduct.customers.find(x => x.quantity == 0 || !x.priceCalculated);
        if (exist) {
            return false;
        }
        let limitNotExceeded = true;

        for (let i = 0; i < order.value.newproduct.customers.length; i++) {
            const customer = order.value.newproduct.customers[i];
            if (!customer.customerInfo.couponId || customer.customerInfo.coupon == null) {
                continue;
            }

            if (customer.customerInfo.coupon?.limitationType == CouponLimitationType.Ticket) {
                if (customer.quantity > customer.customerInfo.coupon.leftLimitationTotal) {
                    customer.limitExceeded = true;
                    limitNotExceeded = false;
                }
            } else {
                if (customer.discountAmount > customer.customerInfo.coupon.leftLimitationTotal) {
                    customer.limitExceeded = true;
                    limitNotExceeded = false;
                }
            }
        }
        if (!limitNotExceeded) {
            swal.fire({
                icon: 'error',
                text: t('alert.coupon.limit-exceeded')
            });
            return false;
        }
        return true;
    });

    function dateToJSON(date: Date | string) {
        return moment(date, moment.ISO_8601).format('YYYY-MM-DD');
    }

    function dateToDisplay(date: Date | string) {
        return moment(date, moment.ISO_8601).format('DD/MM/YYYY');
    }

    function getCustomerPrice(typeId: string) {
        const type = customerTypes.value.find(x => x.id == typeId);
        if (type) {
            return type.priceForOne;
        }
        return 0;
    }

    function getCustomerPriceCurrencySymbol(typeId: string) {
        const type = customerTypes.value.find(x => x.id == typeId);
        if (type) {
            return type.currencySymbol;
        }
        return '';
    }

    async function GetProductCustomers() {
        const priceTypeId = order.value.priceTypeId;
        const productId = order.value.newproduct.productId;
        const apiResult = await api.GetProductCustomers(priceTypeId, productId, order.value.newproduct.date, order.value.businessClientId);
        if (apiResult.errorMessage) {
            swal.fire({
                icon: 'error',
                text: apiResult.data?.error
            });
            return null;
        }
        customerTypes.value = apiResult.data?.customers || customerTypes.value;
        if (apiResult.data?.customers) {
            apiResult.data?.customers.forEach(customer => {
                if (customer.couponId && customer.coupon) {
                    const orderCoupon = order.value.coupons.find(x => x.id == customer.couponId);
                    if (orderCoupon) {
                        customer.coupon = orderCoupon;
                    } else {
                        order.value.coupons.push(customer.coupon);
                    }
                }
            });
        }

        return apiResult.data?.customers;
    }

    function availableTypes(item: OrderProductCustomerDetails) {
        const selectedTypes: string[] = order.value.newproduct.customers
            .filter((x: OrderProductCustomerDetails) => x.customerInfo && x.customerInfo.id != item.id)
            .map((x: OrderProductCustomerDetails) => {
                return x.customerInfo.id;
            });
        return customerTypes.value.filter(x => !selectedTypes.includes(x.id));
    }

    async function CalculateCustomers(customers: ProductToAddRequestCustomerItem[]) {
        const response: CalculateCustomersResponse = {
            isAvailable: null,
            notAvailableCustomerTypeIds: [],
            currencyId: ''
        };
        await Q.delay(50);
        swal.showLoading();
        const request: CalculateProductCustomersRequest = {
            priceTypeId: order.value.priceTypeId,
            productId: order.value.newproduct.productId,
            slotId: order.value.newproduct.slotId,
            date: order.value.newproduct.date,
            dateTime: `${order.value.newproduct.date} ${order.value.newproduct.slot.startTime}`,
            customers: customers
        };

        const apiResult = await api.CalculateProductCustomers(request);
        await Q.delay(400);

        if (apiResult.data?.error) {
            swal.fire({
                title: apiResult.data?.error,
                icon: 'error',
                customClass: {
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: t('button.cancel')
            });
            return response;
        }
        if (apiResult.data && apiResult.data.errorCode > 0) {
            swal.fire({
                title: t('alert.exceeded-quantity'),
                icon: 'error',
                customClass: {
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: t('button.cancel')
            });
            return response;
        }

        if (apiResult.data) {
            calculated.value = true;
            response.isAvailable = false;
            response.currencyId = apiResult.data.currency;
            apiResult.data?.customers.forEach(element => {
                const customer = order.value.newproduct.customers.find((x: OrderProductCustomerDetails) => x.id == element.id);
                if (customer) {
                    customer.price = element.price;
                    customer.name = element.name;
                    customer.priceForOne = element.priceForOne;
                    customer.discountAmount = element.discountAmount;
                    customer.couponId = element.customerInfo?.couponId;
                }
            });
            order.value.newproduct.currencyId = apiResult.data.currency;
            order.value.newproduct.currencySign = apiResult.data.currencySign;
            order.value.newproduct.price = apiResult.data.price;
            order.value.newproduct.customerTotalToPay = apiResult.data.customerTotalToPay;
            order.value.newproduct.businessClientTotalToPay = apiResult.data.businessClientTotalToPay;
            order.value.newproduct.discountTotal = apiResult.data.discountTotal;
            order.value.newproduct.priceTotalWithOutDiscount = apiResult.data.priceTotalWithOutDiscount;
            order.value.newproduct.productName = apiResult.data.name;
            order.value.newproduct.isAvailable = apiResult.data.isAvailable;
            order.value.newproduct.notAvailableCustomerTypeIds = apiResult.data.notAvailableCustomerTypeIds;
            const apiSlot = apiResult.data.slot;
            if (apiSlot) {
                if(order.value.newproduct.slot){
                    order.value.newproduct.slot.id = apiSlot.id;
                    order.value.newproduct.slot.duration = apiSlot.duration;
                    order.value.newproduct.slot.endTime = apiSlot.endTime;
                    order.value.newproduct.slot.endTimeView = apiSlot.endTime ? removemillis(apiSlot.endTime) : getEndTime(apiSlot.startTime, apiSlot.duration);
                    order.value.newproduct.slot.quantity = apiSlot.quantity;
                    order.value.newproduct.slot.startTime = apiSlot.startTime;
                    order.value.newproduct.slot.startTimeView = removemillis(apiSlot.startTime);
                }else{
                    order.value.newproduct.slot = {
                        id: apiSlot.id,
                        duration: apiSlot.duration,
                        endTime: apiSlot.endTime,
                        endTimeView: apiSlot.endTime ? removemillis(apiSlot.endTime) : getEndTime(apiSlot.startTime, apiSlot.duration),
                        quantity: apiSlot.quantity,
                        startTime: apiSlot.startTime,
                        startTimeView: removemillis(apiSlot.startTime)
                    } as Slot;
                }
                
                response.isAvailable = apiResult.data.isAvailable;
                qtyNotOversized.value = response.isAvailable;
                if (!response.isAvailable) {
                    swal.fire({
                        icon: 'warning',
                        text: t('alert.exceeded-quantity'),
                        customClass: {
                            confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4'
                        },
                        buttonsStyling: false
                    });
                    return response;
                }
            }
        }
        swal.close();
        return response;
    }
    function GetValidatedCustomersOrError(): ProductToAddRequestCustomerItem[] | string {
        const customers: ProductToAddRequestCustomerItem[] | null = order.value.newproduct.customers.map(
            (x: OrderProductCustomerDetails) =>
                ({
                    id: x.id,
                    quantity: x.quantity,
                    discountAmount: x.discountAmount,
                    priceForOne: x.priceForOne,
                    couponId: x.customerInfo?.couponId,
                    date: x.date,
                    slot: x.slot,
                    slotId: x.slotId
                } as ProductToAddRequestCustomerItem)
        );

        if (!customers || !customers.length) {
            return 'Need add customers';
        }
        if (customers.find((x: any) => !x.id || !x.quantity || x.quantity == 0)) {
            return 'Need type all fields';
        }
        return customers;
    }
    async function CalculateEventProductCustomers(customer: OrderProductCustomerDetails) {
        if (customer.customerInfo != null) {
            customer.id = customer.customerInfo.id;
        }

        const customers = GetValidatedCustomersOrError();
        if (typeof customers == 'string') {
            return {
                isAvailable: null,
                notAvailableCustomerTypeIds: [],
                currencyId: ''
            };
        }
        return await CalculateCustomers(customers);
    }

    async function CalculateOnClickProductCustomers() {
        const customers = GetValidatedCustomersOrError();
        if (typeof customers == 'string') {
            swal.fire({
                title: customers,
                icon: 'error',
                customClass: {
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: t('button.cancel')
            });
            return {
                isAvailable: null,
                notAvailableCustomerTypeIds: []
            };
        }
        return await CalculateCustomers(customers);
    }

    return {
        valid,
        dateToJSON,
        dateToDisplay,
        availableTypes,
        getCustomerPrice,
        getCustomerPriceCurrencySymbol,
        GetValidatedCustomersOrError,
        GetProductCustomers,
        CalculateCustomers,
        CalculateEventProductCustomers,
        CalculateOnClickProductCustomers,
        customerTypes,
        calculated,
        qtyNotOversized
    };
}
